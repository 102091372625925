import React, {useState} from "react"
import useContactForm from './use-contact-form'
import validate from "./validate-contact-form";

const ContactFormComponent = (props) => {
    const data = props.data;
    const texts = props.texts
    const [showForm, setShowForm] = useState(true)
    const t = (key, vocabulary) => {
        return vocabulary[key] || `{{${key}}}`
    }
    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting
    } = useContactForm(sendFeedback, validate);

    const cantBeSubmitted = errors.name || errors.email || errors.message

    function sendFeedback() {
        setShowForm(false)
    }

    return (
        <div className="formHolder">
            {/*<div>*/}
            {/*    <pre>{JSON.stringify(values, null, 2)}</pre>*/}
            {/*</div>*/}
            {showForm && (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <div className="formWrapper">
                        <div className={errors.firstName ? "formGroup hasError" : "formGroup"}>
                            <label htmlFor="firstName" className="contactUsLabel">{t(data.firstName, texts)}</label>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control inputContactUs"
                              placeholder={t(data.firstName, texts)}
                              name='firstName'
                              onChange={handleChange}
                              required
                              value={values.firstName || ''}
                            />
                            {errors.firstName && (
                              <>
                                  <div className="errorBtn">
                                  </div>
                                  <div className="errorMessage">{errors.firstName}</div>
                              </>
                            )}
                        </div>
                        <div className={errors.lastName ? "formGroup hasError" : "formGroup"}>
                            <label htmlFor="lastName">{t(data.lastName, texts)}</label>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control inputContactUs"
                              placeholder={t(data.lastName, texts)}
                              name='lastName'
                              onChange={handleChange}
                              required
                              value={values.lastName || ''}
                            />
                            {errors.lastName && (
                              <>
                                  <div className="errorBtn">
                                  </div>
                                  <div className="errorMessage">{errors.lastName}</div>
                              </>
                            )}
                        </div>
                        <div className={errors.email ? "formGroup hasError" : "formGroup"}>
                            <label htmlFor="email">{t(data.email, texts)}</label>
                            <input
                              autoComplete="off"
                              type="email"
                              className="form-control inputContactUs"
                              placeholder={t(data.email, texts)}
                              name='email'
                              onChange={handleChange}
                              required
                              value={values.email || ''}
                            />
                            {errors.email && (
                              <>
                                  <div className="errorBtn">
                                  </div>
                                  <div className="errorMessage">{errors.email}</div>
                              </>
                            )}

                        </div>
                        <div className={errors.tel ? "formGroup hasError" : "formGroup"}>
                            <label htmlFor="tel">{t(data.phoneNumber, texts)}</label>
                            <input
                              autoComplete="off"
                              type="tel"
                              className="form-control inputContactUs"
                              placeholder={t(data.phoneNumber, texts)}
                              name='tel'
                              onChange={handleChange}
                              required
                              value={values.tel || ''}
                            />
                            {errors.tel && (
                              <>
                                  <div className="errorBtn">
                                  </div>
                                  <div className="errorMessage">{errors.tel}</div>
                              </>
                            )}
                        </div>
                        <div className={errors.message ? "formGroup hasError fullWidth" : "formGroup fullWidth"}>
                            <label htmlFor="firstName">{t(data.message, texts)}</label>
                        <input
                          className="form-control inputContactUs"
                          placeholder='Enter your message'
                          name='message'
                          onChange={handleChange}
                          required
                          value={values.message || ''}
                        />
                            {errors.message && (
                              <>
                                  <div className="errorBtn">
                                      {/*<img src={ErrorIcon} alt=""/>*/}
                                  </div>
                                  <div className="errorMessage">{errors.message}</div>
                              </>
                            )}
                        </div>
                        <input type="text" name="_gotcha" style={{display: "none"}} />
                    </div>
                    <div className="btnHolder">
                        {isSubmitting ?
                            (
                                <div className='loaderHolder'>
                                    <div className='loader-md'/>
                                </div>
                            ) :
                            (
                                <button type="submit" className="button contactUsButton" disabled={cantBeSubmitted}>
                                    {/* {t(data.button, texts)} */}
                                    GET IN TOUCH
                                </button>
                            )
                        }
                    </div>
                </form>
            )}
            {!showForm && (
                <div className="formSuccessMessage">
                    {t(data.sent, texts)} <span role='img' aria-label='emoji smile'>🙂</span>
                </div>
            )}
        </div>
    )
}

export default ContactFormComponent