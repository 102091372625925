import React  from 'react'
import ContactFormComponent from "../components/contact-us/contact-form/contact-form.component";
import {MainLayout} from "../layouts/main.layout";
import Seo from "../components/seo";
import bgImage from '../assets/img/contactUsBg.svg'
import {graphql} from "gatsby";
import { Helmet } from 'react-helmet';
import Hand from '../assets/img/handheart.svg'
import Phone from '../assets/img/smartphone.svg'
import Headphones from '../assets/img/headphones.svg'
import Window from '../assets/img/window.svg'

const ContactUs = (props) => {
  const location = props.location;
  const data = props.data.allDataJson.edges[0].node.contactUs;
  const texts = props.data.allTexts.edges[0].node
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  return (
    <>
      {/* <Seo title='UploadLetters.com - Contact Us' description='Discover why businesses trust UploadLetters.com for Certified Mail, Priority Mail, and First-Class mailing and tracking services. Contact us for secure online print and mail. Mail letters online.' location={location.pathname} /> */}
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <div className="contactUsPageHolderWrapper">
          <h1 className="introContactUsSlideContentTitle">
            Are you looking for something specific? <br />
            We are here to help.
          </h1>
          <div className="contactUsContainer">
            <div className="contactUsLeftBlock">
              <div className="contactUsLeftBlockTitle">NEW CUSTOMER</div>
              <img src={Hand} alt="Hand" className="contactUsBlockImg" />
              <div className="contactUsBlockDescr">
                Would you like to request a free demo, or have questions about
                our service and pricing?
              </div>
              <a
                href="mailto:sales@uploadletters.com"
                className="contactUsLinkLeft"
              >
                sales@uploadletters.com
              </a>
            </div>
            <div className="contactUsRightBlock">
              <div className="contactUsBlockTitle">EXISTING CUSTOMER</div>
              <div className="contactUsRightBlockContainer">
                <div className="contactUsRightSubBlock">
                  <img src={Phone} alt="Phone" className="contactUsBlockImg" />
                  <div className="contactUsBlockDescr">
                    Need help with an order or have questions about the
                    application? Please include an OrderID if applicable.
                  </div>
                  <a
                    href="mailto:support@uploadletters.com"
                    className="contactUsLinkRight1"
                  >
                    support@uploadletters.com
                  </a>
                </div>
                <div className="contactUsRightSubBlock">
                  <img
                    src={Headphones}
                    alt="Headphones"
                    className="contactUsBlockImg"
                  />
                  <div className="contactUsBlockDescr">
                    Have questions about your enterprise account, invoicing, or
                    general support?
                  </div>
                  <a
                    href="mailto:sales@uploadletters.com"
                    className="contactUsLinkRight2"
                  >
                    enterpriseaccounts@uploadletters.com
                  </a>
                </div>
                <div className="contactUsRightSubBlock">
                  <img
                    src={Window}
                    alt="Window"
                    className="contactUsBlockImg"
                  />
                  <div className="contactUsBlockDescr">
                    Need help with our API or experiencing a technical issue?
                  </div>
                  <a
                    href="mailto:sales@uploadletters.com"
                    className="contactUsLinkRight3"
                  >
                    technicalservice@uploadletters.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="contactUsPageHolder">
            <img src={bgImage} className="contactUsBg" />
            <div className="pageContent">
              <div className="mainContainer">
                <div className="contactUsWrapper">
                  {/* <div className="textPart">
      <h1>{t(data.greeting, texts)}</h1>
    </div> */}
                  <div className="formPart">
                    <h1 className="ContactUsTitle">{t(data.title, texts)}</h1>
                    <div className="contactUsSubTitle">I'M NEW HERE</div>
                    <div className="contactUsDescrForm">
                      Can’t find who you are looking for? <br />
                      Not sure who to
                      contact at <a href="https://uploadletters.com/"
                className="contactUsFormLink">uploadLetters.com</a>  or
                      what’s the right fit for your company? Not a problem.
                      We'll help you find a solution that will best suit your
                      needs.
                    </div>
                    <ContactFormComponent data={data} texts={texts} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  )
}

ContactUs.defaultProps = {
  location: {},
};

export const query = graphql`
    query {
      allDataJson {
        edges {
          node {
            contactUs {
              button
              email
              firstName
              greeting
              lastName
              message
              phoneNumber
              sent
              title
            }
          }
        }
      }
      allTexts {
        edges {
          node {
            contactUs_button
            contactUs_email
            contactUs_firstName
            contactUs_greeting
            contactUs_lastName
            contactUs_message
            contactUs_phoneNumber
            contactUs_sent
            contactUs_title
          }
        }
      }
    }
    `

export default ContactUs

export const Head = () => (
  <Seo title="UploadLetters.com - Contact Us" description="Discover why businesses trust UploadLetters.com for Certified Mail, Priority Mail, and First-Class mailing and tracking services. Contact us for secure online print and mail. Mail letters online." />
)