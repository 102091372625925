export default function validate(values) {
    let errors = {};
    if (!values.email) {
        errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }
    if (!values.firstName) {
        errors.firstName = 'First name is required';
    }
    if (!values.lastName) {
        errors.lastName = 'Last name is required';
    }
    if (!values.tel) {
        errors.tel = 'Telephone number is required';
    }
    if (!values.message) {
        errors.message = "Message field can't be empty";
    }
    return errors;
};