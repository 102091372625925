import { useState, useEffect } from 'react';

const useContactForm = (callback, validate) => {

    const [values, setValues] = useState(
      {
          firstName: '',
          lastName: '',
          tel: '',
          email: '',
          message: ''
      }
    );
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    // console.log(errors)
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors, callback, isSubmitting]);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const errors = validate(values);

        if (errors.name || errors.email || errors.message) {
            setErrors(validate(values));
            return
        }
        setErrors({})
        setIsSubmitting(true);
        const data = new FormData();
        data.append('firstName', values.firstName)
        data.append('lastName', values.lastName)
        data.append('email', values.email)
        data.append('message', values.message)
        const xhr = new XMLHttpRequest();
        xhr.open('post', "https://formspree.io/f/mzbolzgo");
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                setValues({
                    firstName: '',
                    lastName: '',
                    tel: '',
                    email: '',
                    message: ''
                })
                // this.setState({ status: "SUCCESS" });
            } else {
                // this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    };

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
        setErrors(values => ({ ...values, [event.target.name]: null }));
        console.log(event.target.name)
    };

    return {
        handleChange,
        handleSubmit,
        values,
        errors,
        isSubmitting
    }
};

export default useContactForm;